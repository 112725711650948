import React, { memo } from 'react';

import InstagramFeedGrid from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';


import styled, { css } from 'styled-components';
import SectionHeadline from 'components/elements/SectionHeadline';

import PropTypes from 'prop-types';


const InstagramFeed = ({
  events,
  dispatch,
}) => {


  return (
    <Container>
      <StyledHeadline>Social Club</StyledHeadline>
      <InstagramFeedGrid token="IGQWRPWUZAnSUh1TFl1cThBM2ROcmVDMGhjT0F4a0c3N3c0NHpkdTVSTFlvVVZACaklrdUhnVG1oWWc0X3I3N182Vlg1ZAlBEWEMzbHYyYWQ0QXM5VHFxTUd3TnJtZAFU4X0J3TmtQbDRwRmNvOUdSdHhWN3Y2Qno2SWsZDIGQWRNSWNzU1A1VGxfQk5UM0xKWkswdGkyNlZAOaTdJMkdVTGQxQkh4dHlFOU04bnFBOGpaVWlHOVU3MkZADYzhrQWRVaXdHMHBpNmVmcWRTbG5NaGdiV1lETEdOT2tEMGE3QmVjZAHFZAZAWI1N3NIdllzcHQtSlM5akkZD"  counter="6"/>
    </Container>
  )
};

InstagramFeed.propTypes = {
  mapIsOpen: PropTypes.bool
};

export default InstagramFeed;


const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.Primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0;
  padding-bottom: 6em;
`;

const StyledHeadline = styled(SectionHeadline)`
  margin-bottom: 3em;
`;

